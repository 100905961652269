// ** Initial State
const initialState = {
  allData: [],
  data: [],
  currentPage: 0,
  total: 1,
  params: {},
  selectedUser: null,
  statusModal: false,
  currentModal: '',
  comissionPrice: ''
}

const reportstatuses = (state = initialState, action) => {
  console.log('reportstatuses state', state)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CURRENT_PAGE':
      return { ...state, currentPage: action.payload }
    case 'GET_STATUS_MODAL_STATUS':
      // console.log("TT")
      // console.log(action.payload)
      return { ...state, statusModal: action.payload, currentModal: action.currentModalIndex }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'GET_CP':
      return { ...state, comissionPrice: action.payload }
    case 'ADD_USER':
      return { ...state }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state }
  }
}

export default reportstatuses
