// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import grafts from '@src/views/apps/grafts/store/reducer'
import hotels from '@src/views/apps/hotels/store/reducer'
import sessions from '@src/views/apps/sessions/store/reducer'
import techniques from '@src/views/apps/techniques/store/reducer'
import techsessionpricing from '@src/views/apps/techsessionpricing/store/reducer'
import additionalsurgeries from '@src/views/apps/additionalsurgerie/store/reducer'
import reportstatuses from '@src/views/apps/reportstatus/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import consultationform from '@src/views/apps/consultationForm/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  grafts,
  hotels,
  sessions,
  techniques,
  techsessionpricing,
  additionalsurgeries,
  reportstatuses,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  consultationform
})

export default rootReducer
