// ** Initial State
const initialState = {
  currentGender: ''
}

const consultationform = (state = initialState, action) => {
  console.log(state)
  // console.log(state)
  // console.log(action)
  switch (action.type) {
    case 'GET_CONSULTATION_GENDER':
      return { ...state, currentGender: action.payload }
    default:
      return { ...state }
  }
}

export default consultationform
