// ** Initial State
const initialState = {
  events: [],
  selectedEvent: {},
  selectedCalendars: ['1', '2', '3']
}

const calenderReducer = (state = initialState, action) => {
//  console.log(action.event)
  // console.log(state)
  switch (action.type) {
    case 'FETCH_EVENTS':

      return { ...state, events: action.events }
    case 'ADD_EVENT':
      return { ...state }
    case 'REMOVE_EVENT':
      return { ...state }
    case 'UPDATE_EVENT':
      return { ...state }
    case 'UPDATE_FILTERS':
      // ** Updates Filters based on action filter
      // console.log(state.selectedCalendars)
      // console.log(action.filter)
      const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0
      }
      // console.log(state.selectedCalendars)
      return { ...state }
    case 'UPDATE_ALL_FILTERS':
      // ** Updates All Filters based on action value
      // console.log(action)
      const value = action.value
      let selected = []
      if (value === true) {
        selected = ['1', '2', '3', '4', '5']
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    default:
      return state
  }
}

export default calenderReducer
